<template>
  <div>
    <div>
      <div class="introduce">
        <p>
          农投发展在供应链行业积累了丰富的经验，能够结合行业经验和货品特点，构筑完善的业务和风险规则管控体系，为进口农产品、能源等行业提供从贸易执行、报关报检、仓储物流、金融服务到风险管控的一站式服务平台，实现全流程监管、全节点可控。
        </p>
        <img src="../assets/images/index.png" alt="">
      </div>
    </div>
    <tab-bar active="index" />
    <!--锁定弹窗-->
    <van-dialog v-model="enterpriselockStatus" title="" :show-cancel-button="false" :show-confirm-button="false" :lazy-render="false">
      <p class="lockStatus">
        贵公司的账号已经被平台锁定,请联系管理员解锁,谢谢!
      </p>
    </van-dialog>
  </div>
</template>

<script>
import TabBar from '@/components/tab-bar'
import { Dialog } from 'vant'
export default {
  components: {
    TabBar,
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      // 锁定状态：0：未锁定1：已锁定
      enterpriselockStatus: this._.get(this.$store.state, 'employeeInfo.enterpriselockStatus', '') || false
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
  .introduce{
    overflow: hidden;
    font-size: 16px !important;
    color: #646566;
    padding-bottom: 55px;
  }
  .introduce p {
    text-indent:32px;
    padding: 45px 20px 20px !important;
  }
  .introduce img {
    width: 96%;
    padding: 0px 8px;
    margin: 0 auto;
  }
</style>
