var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("tab-bar", { attrs: { active: "index" } }),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "",
            "show-cancel-button": false,
            "show-confirm-button": false,
            "lazy-render": false
          },
          model: {
            value: _vm.enterpriselockStatus,
            callback: function($$v) {
              _vm.enterpriselockStatus = $$v
            },
            expression: "enterpriselockStatus"
          }
        },
        [
          _c("p", { staticClass: "lockStatus" }, [
            _vm._v(" 贵公司的账号已经被平台锁定,请联系管理员解锁,谢谢! ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "introduce" }, [
        _c("p", [
          _vm._v(
            " 农投发展在供应链行业积累了丰富的经验，能够结合行业经验和货品特点，构筑完善的业务和风险规则管控体系，为进口农产品、能源等行业提供从贸易执行、报关报检、仓储物流、金融服务到风险管控的一站式服务平台，实现全流程监管、全节点可控。 "
          )
        ]),
        _c("img", {
          attrs: { src: require("../assets/images/index.png"), alt: "" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }